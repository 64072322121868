(function () {
  "use strict";

  // @include "./partials/add-hotdoc-globals.js"

  hotdoc.log("START widgets.min.js");

  // @include "./partials/return-if-old-browser.js"

  // Add a JS file to the <head>
  // Only if the file doesn't exist yet
  function addJs(fileName) {
    const url = `${hotdoc.assetsURL}js/${fileName}`;
    const notYetAdded =
      document.querySelectorAll(`script[src="${url}"]`).length < 1;
    if (notYetAdded) {
      hotdoc.log(`Appending ${fileName} to <head>`);
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      document.head.appendChild(script);
    } else {
      hotdoc.log(`${fileName} is already in the <head>`);
    }
  }

  // Add a CSS file to the <head>
  // Only if the file doesn't exist yet
  function addCss(fileName) {
    const url = `${hotdoc.assetsURL}css/${fileName}`;
    const notYetAdded =
      document.querySelectorAll(`link[href="${url}"]`).length < 1;
    if (notYetAdded) {
      hotdoc.log(`Appending ${fileName} to <head>`);
      let link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = url;
      document.head.appendChild(link);
    } else {
      hotdoc.log(`${fileName} is already in the <head>`);
    }
  }

  // Add the meta viewport tag to the <head>
  // Necessary for mobile responsive CSS
  function addViewportMeta() {
    const notYetAdded =
      document.querySelectorAll(
        `meta[name="viewport"][content="width=device-width, initial-scale=1.0"]`
      ).length < 1;
    if (notYetAdded) {
      let meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content = "width=device-width, initial-scale=1.0";
      document.head.appendChild(meta);
    }
  }

  function ensureIframeWaypoints(iframes) {
    iframes.forEach((iframe) => {
      try {
        const src = iframe.getAttribute("src");
        if (!src) {
          return;
        }

        const url = new URL(src);
        if (!url.searchParams.get("wp")) {
          url.searchParams.append("wp", "w_iframe");
          iframe.setAttribute("src", url.href);
        }
      } catch (_error) {
        //NOOP
      }
    });
  }

  function ensureLightboxWaypoints(lightboxes) {
    lightboxes.forEach((lightbox) => {
      try {
        const href = lightbox.getAttribute("href");
        if (!href) {
          return;
        }

        const url = new URL(href);
        if (!url.searchParams.get("wp")) {
          url.searchParams.append("wp", "w_lightbox");
          lightbox.setAttribute("href", url.href);
        }
      } catch (_error) {
        //NOOP
      }
    });
  }

  function ensureDirectLinkWaypoints() {
    // Make sure all HotDoc listing links have waypoint attribution setup
    const links = document.querySelectorAll("a");

    links.forEach((link) => {
      try {
        const href = link.getAttribute("href");
        if (!href) {
          return;
        }

        const url = new URL(href);

        // Return if not a HotDoc URL in production
        if (
          !url.hostname.endsWith("hotdoc.com.au") &&
          hotdoc.environment !== "development"
        ) {
          return;
        }

        // Not a listing URL
        if (
          !url.pathname.includes("/medical-centres") ||
          !url.pathname.includes("/doctors")
        ) {
          return;
        }

        // Is a direct hotdoc listing URL, so attach waypoint
        if (!url.searchParams.get("wp")) {
          url.searchParams.append("wp", "w_direct");
          link.setAttribute("href", url.href);
        }
      } catch (_error) {
        //NOOP
      }
    });
  }

  // Evaluate which widget HTML snippets are present in the DOM and decide which extra files to load
  // eslint-disable-next-line no-unused-vars
  function init() {
    hotdoc.log(`DOM ready, init() widgets.min.js`);
    const lightboxes = document.querySelectorAll(
      '[data-hotdoc-widget="lightbox"]'
    );
    const iframes = document.querySelectorAll(
      'iframe[data-hotdoc-widget="iframe"]'
    );
    const buttons = document.querySelectorAll("[data-hotdoc-button]");

    hotdoc.log(`Found ${iframes.length} iframe snippets`);
    hotdoc.log(`Found ${lightboxes.length} lightbox snippets`);
    hotdoc.log(`Found ${buttons.length} button snippets`);

    // HotDoc buttons only need CSS
    if (buttons.length) {
      addCss("hotdoc-buttons.min.css");
    }

    // HotDoc iframes only need JS
    if (iframes.length) {
      ensureIframeWaypoints(iframes);
      addJs("hotdoc-iframes.min.js");
      addCss("hotdoc-iframes.min.css");
      addViewportMeta();
    }

    // Lightboxes require both JS and CSS
    // Important: Run this block last.
    if (lightboxes.length) {
      ensureLightboxWaypoints(lightboxes);
      addJs("hotdoc-lightboxes.min.js");
      addCss("hotdoc-lightboxes.min.css");
      addViewportMeta();
    }

    ensureDirectLinkWaypoints();
  }

  // @include "./partials/init-on-dom-ready.js"
})();
